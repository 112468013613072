<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Organization</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Organization</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <section class="content" v-if="has_permission === true">
      <div class="container-fluid">
        <div class="card" id="organizations-organizations">
          <div class="card-header">
            <div class="btn-toolbar pull-right">
              <el-dropdown split-button :trigger="'click'"
                           @command="handleSelectedRows"
                           type="primary" v-if="this.selected_row.length" class="mr-1">
                {{ this.selected_row.length }} {{ 'item' | pluralize(this.selected_row.length) }} selected
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click="bulkDelete()" command="delete">Delete</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <button class="btn btn-info" type="button" @click="showFilter = !showFilter">
                <i class="fa fa-filter"></i> Filter
              </button>
            </div>
            <div class="btn-toolbar pull-right">
              <router-link :to="{ name: 'OrganizationForm' }" class="btn btn-success">
                <i class="fas fa-plus"></i> New
              </router-link>
            </div>
          </div>
          <form @submit.prevent="getOrganizations" method="GET" novalidate>
            <div class="card-body" v-if="showFilter">
              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group form-row">
                    <label class="col-md-3 col-form-label" for="org_name">Name</label>
                    <div class="col-md-9">
                      <input type="text" class="form-control" id="org_name" v-model="filter.org_name"
                             placeholder="Name">
                    </div>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-9 offset-md-3">
                      <button type="submit" class="btn btn-common m-2px"><i class="fas fa-search"></i> Search</button>
                      <button type="reset" class="btn btn-warning" @click="resetFilter()"><i class="fas fa-undo"></i>
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>

          <div class="card-body table-responsive-md p-0">
            <table class="table">
              <thead>
              <tr>
                <th style="width: 10px">
                  <input type="checkbox" v-model="is_selected_all" @click="selectedRowAll()">
                </th>
                <th style="width: 10px">ID</th>
                <th>Organization Name
                  <sort-icons :_sort="'org_name'"/>
                </th>
                <th class="text-right">Action</th>
              </tr>
              </thead>
              <tbody class="organizations-organizations">
              <tr v-for="(val, index) in organizations.data">
                <td>
                  <input type="checkbox" v-model="selected_row" :value="val.id">
                </td>
                <td>{{ val.id }}</td>
                <td>{{ val.org_name }}</td>
                <td class="text-right">
                  <router-link :to="{name: 'OrganizationShow', params: { id: val.id }}"
                               class="btn btn-info btn-sm m-2px">
                    <i class="fas fa-eye"></i>
                  </router-link>
                  <router-link :to="{name: 'OrganizationEdit', params: { id: val.id }}"
                               class="btn btn-info btn-sm m-2px">
                    <i class="fas fa-pencil-alt"></i>
                  </router-link>
                  <a class="btn btn-danger btn-sm m-2px" href="javascript:void(0)"
                     @click="destroy(val.id, index)"><i class="fas fa-trash"></i></a>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="col-12 p-4" v-if="_.isEmpty(organizations.data)">
              <div class="alert alert-default-warning" role="alert">
                No data found!
              </div>
            </div>
          </div>

          <div class="card-footer">
            <vue-pagination
              :pagination="organizations"
              v-if="organizations.total > organizations.per_page"
              @paginate="getOrganizations()"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="content" v-if="has_permission === false">
      <div class="alert alert-danger alert-dismissible">
        <h5><i class="icon fas fa-ban"></i> Permission denied!</h5>
      </div>
    </section>
  </div>
</template>

<script>
import VuePagination from "@/components/partials/PaginationComponent";
import SortIcons from "@/components/utils/SortIcons";
import {OrganizationsAPI} from "@/services/api";

export default {
  name: 'OrganizationList',
  metaInfo: {
    title: "Organization | Dotlines"
  },
  components: {
    VuePagination, SortIcons
  },
  data: () => ({
    organizations: {
      total: 0,
      per_page: 1,
      from: 1,
      to: 0
    },
    showFilter: false,
    filter: {},
    is_loading: false,
    has_permission: true,
    selected_row: [],
    is_selected_all: false,
  }),
  methods: {
    getOrganizations() {
      this.is_loading = true;
      let query = {...this.filter, ...this.$route.query}

      OrganizationsAPI.index(query).then(organizations => {
        this.organizations = organizations;
      }).catch(error => {
        if (error.response.status === 403) {
          this.has_permission = false;
        }
      }).finally(() => {
        this.is_loading = false;
      });
    },
    resetFilter() {
      const query = Object.assign({}, this.$route.query);
      if (Object.keys(query).length > 0) {
        delete query._sort
        delete query._sort_type
        delete query.page
        // Object.keys(query).forEach((key) => delete query.key);
        this.$router.replace({query});
      }
      this.filter = {}
      this.selected_row = []
      this.is_selected_all = false
      this.getOrganizations()
    },
    destroy: function (id, index) {
      // let _this = this;
      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.is_loading = true;
          OrganizationsAPI.destroy(id)
            .then(response => {
              this.organizations.data.splice(index, 1);
              this.organizations.total--;
              this.$toaster.success(response.message);
            })
            .catch(error => {
              console.log("Could not delete this:: ", error);
              this.$swal.fire('Failed!', 'Failed to delete.', 'error');
            }).finally(() => {
            this.is_loading = false;
          });
        }
      });
    },
    selectedRowAll: function () {
      let isSelectedAll = this.is_selected_all = !this.is_selected_all;
      if (isSelectedAll) {
        var items = [];
        this.organizations.data.forEach(function (item) {
          items.push(item.id);
        })
        this.selected_row = items;
      } else {
        this.selected_row = [];
      }
    },
    handleSelectedRows(command) {
      if (command === 'delete') {
        console.log('Selected ID to Delete:: ', this.selected_row)
      }
    }
  },
  mounted: function () {
    if (this.isMobile()) {
      window.$(document).find('.push-menu-class').trigger('click');
    }
    this.getOrganizations();
    this.$root.$on('sort-data', () => {
      this.$root.$emit('show-icon');
      this.getOrganizations();
    });
  }
}
</script>
